import { ImageCover } from '/features/buildingBlocks/Image'

import styles from './ImageInline.css'

export function ImageInline({ image, layoutClassName = undefined }) {
  return (
    <section data-x='large-image' className={cx(styles.component, layoutClassName)}>
      <ImageCover aspectRatio={1} layoutClassName={styles.imageLayout} {...{ image }} />
    </section>
  )
}
